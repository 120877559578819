// src/components/RemainingGuesses.js
import React from 'react';

const RemainingGuesses = ({ remainingGuesses }) => {
  const circles = Array.from({ length: 5 }, (_, index) => (
    <span
      key={index}
      style={{
        display: 'inline-block',
        width: '2rem',
        height: '2rem',
        borderRadius: '20%',
        border: '2px solid green',
        backgroundColor: index < remainingGuesses ? 'green' : 'transparent',
        margin: '0 0.2rem',
      }}
    ></span>
  ));

  return <div style={{ marginTop: '1rem' }}>{circles}</div>;
};

export default RemainingGuesses;