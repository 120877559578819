// src/App.js
import React, { useState, useRef, useEffect } from 'react';
import EmojiDisplay from './components/EmojiDisplay';
import TitleInput from './components/TitleInput';
import SubmitButton from './components/SubmitButton';
import RemainingGuesses from './components/RemainingGuesses';
import puzzles from './puzzles.json';
import './App.css';

const firstDay = 19964;
const sadEmojis = ['😕', '🙁', '☹️', '😬'];

// TBD
// add hebrew support

const App = () => {
  const getDailyPuzzle = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const puzzleIndex = urlParams.get('puzzle');
    if (puzzleIndex !== null) {
      return puzzleIndex % puzzles.length;
    }

    const epoch = new Date(1970, 0, 1);
    const today = new Date();
    const diffInTime = today.getTime() - epoch.getTime();
    const dayNumber = Math.floor(diffInTime / (1000 * 3600 * 24));
    const puzzleNumber = dayNumber - firstDay;
    console.log("puzzle #:", puzzleNumber);
    return puzzleNumber % puzzles.length;
  };

  const whitespace = /[ -,'".]/g;
  const nonWhitespace = /[^ -,'".]/g;
  const endingWhitespace = /[ -,'".]*$/g;

  const [puzzleIndex, setPuzzleIndex] = useState(getDailyPuzzle());
  const [puzzle, setPuzzle] = useState(-1);
  const [input, setInput] = useState('');
  const [displayTitle, setDisplayTitle] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [remainingGuesses, setRemainingGuesses] = useState(5);
  const [hintIndex, setHintIndex] = useState(0);
  const [shownHints, setShownHints] = useState([]);
  const [isGameOver, setIsGameOver] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  const inputRef = useRef(null);

  const loadPuzzle = (index) => {
    if (index === -1) {
      return {};
    }
    const puzzle = puzzles[index];
    setInput('');
    setDisplayTitle(puzzle.title.replace(new RegExp(nonWhitespace), '_'));
    setResultMessage('');
    setRemainingGuesses(5);
    setHintIndex(0);
    setShownHints([]);
    setIsGameOver(false);
    setCursorPosition(0);

    return puzzle;
  };

  useEffect(() => {
    document.title = "Emojified";
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, 0);
    }
  }, []);

  useEffect(() => {
    console.log("loading puzzle:", puzzleIndex);
    setPuzzle(loadPuzzle(puzzleIndex));
  }, [puzzleIndex]);

  useEffect(() => {
    console.log("displayTitle:", displayTitle, "input:", input, "length:", input.length);
    if (inputRef.current) {
      inputRef.current.setSelectionRange(input.length, input.length);
    }
  }, [displayTitle, input, cursorPosition]);

  const handleTitleChange = (e) => {
    if (isGameOver) return;

    let guess = e.target.value.replace(/\n/g, '').replace(/_/g, '').replace(new RegExp(endingWhitespace), '');
    let cursorPosition = e.target.selectionStart;

    console.log("cursorPosition:", cursorPosition, "guess:", guess, "title:", puzzle.title);

    if (guess.length > puzzle.title.length) {
      return;
    }

    while (e.nativeEvent.inputType === 'deleteContentBackward' && new RegExp(whitespace).test(puzzle.title[cursorPosition]) && cursorPosition > 0) {
      guess = guess.slice(0, cursorPosition - 1) + guess.slice(cursorPosition);
      cursorPosition--;
    }

    while (new RegExp(whitespace).test(puzzle.title[guess.length])) {
      guess += puzzle.title[guess.length];
    }

    let newDisplayTitle = guess.concat(puzzle.title.slice(guess.length).replace(new RegExp(nonWhitespace), '_'));

    setInput(guess);
    setDisplayTitle(newDisplayTitle);
    setCursorPosition(cursorPosition);
  };

  const handleSubmit = () => {
    if (isGameOver) return;
    if (input.length !== puzzle.title.length) {
      if (inputRef.current) {
        inputRef.current.classList.add('input-error');
        setTimeout(() => {
          inputRef.current.classList.remove('input-error');
        }, 1000);
      }
      return;
    }
    if (input.trim().toLowerCase() === puzzle.title.trim().toLowerCase()) {
      setResultMessage('Success! 🎉🤩😎');
      setIsGameOver('won');
    } else {
      setRemainingGuesses(remainingGuesses - 1);
      if (remainingGuesses - 1 === 0) {
        setResultMessage(`Failed! 😢`);
        setDisplayTitle(puzzle.title);
        setIsGameOver('lost');
      } else {
        // emoji getting sadder as guesses decrease
        setResultMessage(`Wrong ${sadEmojis[5 - remainingGuesses]}`);
        if (hintIndex < puzzle.hints.length) {
          setShownHints((prevHints) => [...prevHints, puzzle.hints[hintIndex]]);
          setHintIndex((prevIndex) => prevIndex + 1);
        }
        if (remainingGuesses - 1 === 1) {
          const words = puzzle.title.split(/[ -']/g);
          if (words.length > 1) {
            setInput(words[0]);
            setDisplayTitle(words[0] + puzzle.title.slice(words[0].length).replace(new RegExp(nonWhitespace), '_'));
          } else {
            setInput(puzzle.title[0]);
            setDisplayTitle(puzzle.title[0] + puzzle.title.slice(1).replace(new RegExp(nonWhitespace), '_'));
          }
        } else {
          setInput('');
          setDisplayTitle(puzzle.title.replace(new RegExp(nonWhitespace), '_'));
        }
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(0, 0);
        }
      }
    }
  };

  const handleForward = () => {
    if (puzzleIndex < getDailyPuzzle()) {
      setPuzzleIndex(puzzleIndex + 1);
    }
  };

  const handleBackward = () => {
    if (puzzleIndex > 0) {
      setPuzzleIndex(puzzleIndex - 1);
    }
  };

  return (
    <div className="App">
      <h1>Emojified</h1>
      <EmojiDisplay emojis={puzzle.emoji} type={puzzle.type}/>
      <TitleInput
        displayTitle={displayTitle}
        handleTitleChange={handleTitleChange}
        inputRef={inputRef}
        isGameOver={isGameOver}
        onEnterPress={handleSubmit}
      />
      <div className="navigation-buttons">
        <button className="skip-button" onClick={handleBackward} disabled={puzzleIndex === 0}>👈</button>
        {!isGameOver && <SubmitButton onSubmit={handleSubmit}/>}
        <button className="skip-button" onClick={handleForward} disabled={puzzleIndex >= getDailyPuzzle()}>👉</button>
      </div>
      {!isGameOver && <RemainingGuesses remainingGuesses={remainingGuesses}/>}
      <div style={{marginTop: '1rem', fontSize: '2rem'}}>{resultMessage}</div>
      <div className="hints">
        {shownHints.map((hint, index) => (
          <div key={index}>{hint}</div>
        ))}
      </div>
      <div className="footer">
        &nbsp;&nbsp;📚&nbsp;&nbsp;
        Puzzle #{puzzleIndex + 1}
        &nbsp;&nbsp;🎬&nbsp;&nbsp;
        All rights reserved
        &nbsp;&nbsp;📺&nbsp;&nbsp;
        <a href='https://www.gazith.com' target="_blank" rel="noopener noreferrer">gazith.com</a>
      </div>
    </div>
  );
};

export default App;