import React from 'react';

const SubmitButton = ({ onSubmit }) => {
  return (
    <button className="submit-button" onClick={onSubmit}>
      Check Answer
    </button>
  );
};

export default SubmitButton;