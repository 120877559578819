// src/components/TitleInput.js
import React from 'react';

const TitleInput = ({ displayTitle, handleTitleChange, inputRef, isGameOver, onEnterPress }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onEnterPress();
    }
  };

  return (
    <textarea
      value={displayTitle}
      onChange={handleTitleChange}
      onKeyDown={handleKeyDown}
      ref={inputRef}
      disabled={isGameOver}
      autoCorrect="off"
      spellCheck={false}
      style={{
        backgroundColor: isGameOver === 'lost' ? 'lightgrey' : 'white',
      }}
      className="guess-input"
    />
  );
};

export default TitleInput;