import React from 'react';

const getEmojiByType = (type) => {
  switch (type) {
    case 'book':
      return '📚';
    case 'movie':
      return '🎬';
    case 'show':
      return '📺';
    default:
      return '';
  }
};

const EmojiDisplay = ({ emojis, type }) => {
  return (
    <div style={{textAlign: 'center'}}>
      <div className="puzzle-type">{getEmojiByType(type)}</div>
      <div className="emojis">{emojis}</div>
    </div>
  );
};

export default EmojiDisplay;